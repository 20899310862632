var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4 d-flex justify-center"},[(_vm.isItemsNotEmpty)?_c('v-row',_vm._l((_vm.productItems),function(ref,key){
var id = ref.id;
var images = ref.images;
var name = ref.name;
var stockQty = ref.stockQty;
var capsuleQty = ref.capsuleQty;
var unit = ref.unit;
return _c('v-col',{key:key,attrs:{"cols":"6","lg":"2","sm":"4"}},[_c('div',{staticClass:"card d-flex flex-column align-center"},[_c('v-img',{staticClass:"ma-4",attrs:{"lazy-src":_vm.placeHolder,"src":_vm.getCapsuleProductImage(images),"contain":"","height":"150","width":"150"}}),_c('span',{staticClass:"product-text mt-3 ml-4 mr-4"},[_vm._v(" "+_vm._s(name))]),_c('span',{staticClass:"product-text mt-1 ml-4 mr-4"},[_vm._v("คงเหลือ "+_vm._s(stockQty)+" "+_vm._s(unit))]),_c('div',{staticClass:"d-flex ma-4 justify-center"},[_c('v-img',{staticClass:"mr-2",attrs:{"src":_vm.capsuleImage,"contain":""}}),_c('span',{class:_vm.getClassName(_vm.isMobileScreenSize, 'capsules-text', 'mobile')},[_vm._v(_vm._s(_vm.getCapsuleText(capsuleQty)))])],1),_c('v-btn',{staticClass:"btn-text mb-4",attrs:{"disabled":!_vm.isAbleToRedeem(capsuleQty),"outlined":_vm.isAbleToRedeem(capsuleQty),"color":"secondary"},on:{"click":function($event){return _vm.onRedeemClick(id, capsuleQty)}}},[_vm._v(" แลก Capsule ")])],1)])}),1):_c('span',{staticClass:"no-item-text pa-16"},[_vm._v("ยังไม่มีสินค้าให้แลกในขณะนี้")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }