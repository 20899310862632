<template>
  <v-container class="mt-3 mb-16">
    <div
      class="capsule-banner-container hidden-sm-and-down"
    >
      <v-img
        contain
        src="@/assets/images/banner/capsule-market-banner.png"
        class="image"
      />
      <div
        class="text d-flex flex-column"
      >
        <span class="title-text text-purple">
          Capsule Market
        </span>
        <span class="capsule-banner__text">
          ทุกๆ การใช้จ่าย {{ capsuleConfig }} บาท = ได้รับ 1 Capsule Point
        </span>
        <span class="capsule-banner__text small">
          *สินค้าที่แลก Capsule จะถูกส่งไปพร้อมกับการเปิดบิลครั้งถัดไป
        </span>
      </div>
    </div>
    <div class="d-flex flex-column d-md-none d-lg-none mb-6">
      <span class="title-text text-purple">
        Capsule Market
      </span>
      <span class="capsule-banner__text">
        ทุกๆ การใช้จ่าย {{ capsuleConfig }} บาท = ได้รับ 1 Capsule Point
      </span>
      <span class="capsule-banner__text">
        *สินค้าที่แลก Capsule จะถูกส่งไปพร้อมกับการเปิดบิลครั้งถัดไป
      </span>
    </div>
    <CapsuleProductItems
      :product-items="getCapsuleItems"
      :on-redeem-click="setRedeemPoint"
    />
    <CapsuleRedeemComplete
      :is-show-redeem-dialog="isShowDialogRedeemSuccess"
      :redeem-point="getRedeemPoint"
      @setIsShowRedeemedDialog="setIsShowRedeemDialogComplete"
    />
  </v-container>
</template>

<script>
import CapsuleProductItems from '@/pages/main/views/CapsuleMarket/components/CapsuleProductItems.vue';
import CapsuleRedeemComplete from '@/pages/main/views/CapsuleMarket/components/CapsuleRedeemComplete.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  FETCH_CAPSULE_MARKET_ITEMS,
  REDEEM_ITEMS,
  SHOW_DIALOG_REDEEM_SUCCESS,
} from '@/store/_actionTypes';

export default {
  name: 'CpasuleMarket',
  components: {
    CapsuleProductItems,
    CapsuleRedeemComplete,
  },
  computed: {
    ...mapGetters('capsule-market', [
      'getCapsuleItems',
      'getRedeemPoint',
      'isShowDialogRedeemSuccess',
    ]),
    ...mapState('cart', [ 'capsuleConfig' ]),
    ...mapState('user', [ 'accessToken' ]),
  },
  async created() {
    await this[FETCH_CAPSULE_MARKET_ITEMS](this.accessToken);
  },
  methods: {
    ...mapActions('capsule-market', [
      FETCH_CAPSULE_MARKET_ITEMS,
      REDEEM_ITEMS,
      SHOW_DIALOG_REDEEM_SUCCESS,
    ]),
    async setRedeemPoint(item, capsulePoint) {
      await this[REDEEM_ITEMS]({ id: item, accessToken: this.accessToken, capsulePoint });
    },
    setIsShowRedeemDialogComplete(value) {
      this[SHOW_DIALOG_REDEEM_SUCCESS](value);
    },
  },
};
</script>

<style lang="scss">

.capsule-banner__text {
  font-family: Kanit;
  color: $primary-color-03;
}

.title-text {
  &.text-purple {
    font-weight: 600;
    text-align: left;
    color: $primary-color-03;
  }
}

.banner-container {
  .text {
    position: absolute;
  }
}

.capsule-banner-container {
  position: relative;

  .text {
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translateY(-50%);

    .title-text {
      font-size: 2rem;
    }

    .capsule-banner__text {
      font-size: 1.25rem;

      &.small {
        font-size: 0.75rem;
      }
    }
  }
}

</style>