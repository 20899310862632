<template>
  <div class="mt-4 d-flex justify-center">
    <v-row v-if="isItemsNotEmpty">
      <v-col
        v-for="({ id, images, name, stockQty, capsuleQty, unit }, key) in productItems"
        :key="key"
        cols="6"
        lg="2"
        sm="4"
      >
        <div class="card d-flex flex-column align-center">
          <v-img
            :lazy-src="placeHolder"
            :src="getCapsuleProductImage(images)"
            class="ma-4"
            contain
            height="150"
            width="150"
          />
          <span class="product-text mt-3 ml-4 mr-4"> {{ name }}</span>
          <span class="product-text mt-1 ml-4 mr-4">คงเหลือ {{ stockQty }} {{ unit }}</span>
          <div class="d-flex ma-4 justify-center">
            <v-img
              class="mr-2"
              :src="capsuleImage"
              contain
            />
            <span
              :class="getClassName(isMobileScreenSize, 'capsules-text', 'mobile')"
            >{{ getCapsuleText(capsuleQty) }}</span>
          </div>
          <v-btn
            :disabled="!isAbleToRedeem(capsuleQty)"
            :outlined="isAbleToRedeem(capsuleQty)"
            class="btn-text mb-4"
            color="secondary"
            @click="onRedeemClick(id, capsuleQty)"
          >
            แลก Capsule
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <span
      v-else
      class="no-item-text pa-16"
    >ยังไม่มีสินค้าให้แลกในขณะนี้</span>
  </div>
</template>

<script>
import { getClassName } from '@/helpers/className';
import { mapGetters, mapState } from 'vuex';
import { formatNumber } from "@/helpers/number";

import CapsulesIcon from '@/assets/images/medicine/ic-capsules.svg';
import PlaceHolder from '@/assets/images/placeholder/ic-common-placeholder.svg';
import NotFoundProductImage from '@/assets/images/medicine/not-found-product.png';

export default {
  name: 'CapsuleProductItems',
  props: {
    productItems: {
      type: Array,
      default: () => [],
    },
    onRedeemClick: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    capsuleImage: CapsulesIcon,
    placeHolder: PlaceHolder,
  }),
  computed: {
    ...mapState('app', ['isMobileScreenSize']),
    ...mapGetters('user', ['getCapsule']),
    isItemsNotEmpty() {
      return this.productItems.length > 0;
    },
  },
  methods: {
    getClassName,
    isAbleToRedeem(capsulePoint) {
      return this.getCapsule > capsulePoint;
    },
    getCapsuleText(capsule){
      return `${formatNumber(capsule)} Capsules`
    },
    getCapsuleProductImage(images) {
      if (!images || images.length === 0)
        return NotFoundProductImage;

      return images[0].url;
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/variables.scss';
@import '@/styles/fonts.scss';

.card {
  border-radius: 6px;
  border: solid 1px $white-01;
  background-color: $white-00;
}

.product-text {
  @include sarabun;
  font-size: 14px;
  line-height: 1.57;
  text-align: center;
  color: $black-00;
}

.capsules-text {
  @include sarabun;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  color: $secondary-color-00;

  &.mobile {
    font-size: 12px;
  }
}

.btn-text {
  @include kanit;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: $secondary-color-00;
}

.no-item-text {
  @include kanit;
  font-size: 20px;
  line-height: 1.5;
  color: $black-00;
}
</style>
