<template>
  <Dialog
    :is-show-dialog="isShowRedeemedDialogValue"
    @setIsShowDialog="setIsShowRedeemedDialog"
  >
    <div class="d-flex flex-column align-center mt-1">
      <v-img :src="checkIcon" />
      <span class="title-text mt-3">แลก Capsule สำเร็จ</span>
      <span class="title-text redeem-text mt-2">{{ getRedeemPoint }}</span>
      <span class="title-text description mt-2 mb-4">
        สินค้าแลก Capsule จะถูกนำไปรวมและจัดส่งพร้อมกับบิลรอบถัดไปโดยอัตโนมัติ
      </span>
    </div>
  </Dialog>
</template>

<script>
import { formatNumber } from "@/helpers/number";
import Dialog from '@/components/Dialog.vue';
import CheckIcon from '@/assets/images/logo/tick-green.png';

export default {
  name: 'CapsuleRedeemComplete',
  components: { Dialog },
  props: {
    isShowRedeemDialog: {
      type: Boolean,
      default: () => false,
    },
    redeemPoint: {
      type: Number,
      default: () => 0,
    },
  },
  data: () => ({
    checkIcon: CheckIcon,
  }),
  computed: {
    isShowRedeemedDialogValue: {
      get() {
        return this.isShowRedeemDialog;
      },
    },
    getRedeemPoint(){
      return `คุณได้ใช้ ${formatNumber(this.redeemPoint)} Capsules`
    },
  },
  methods: {
    setIsShowRedeemedDialog(value) {
      this.$emit('setIsShowRedeemedDialog', value);
    },
  },
};
</script>

<style lang="scss">

.title-text {
  font-family: Kanit;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: $black-00;

  &.redeem-text{
    font-size: 24px;
    font-weight: 500;
    color: $primary-color-02;
  }

  &.description {
    font-size: 14px;
    font-weight: 500;
  }
}
</style>
